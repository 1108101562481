<template>
    <onboarding-layout
        :title="$t('pages.identityQuestions.title')"
        :loading="loading"
        :loading-title="$t('global.loadingMessage.verifying')"
        :error-text="errorText"
    >
        <form-container
            ref="form"
            @on-submit="onSubmit"
        >
            <div
                v-for="(data, index) in questionData"
                :key="index"
            >
                <form-radio-group
                    class="mb-5"
                    :name="data.type"
                    :prompt="data.prompt"
                    :options="data.answer"
                    v-model="answerData[index]"
                />
            </div>

            <div v-if="!isChallengeQuestion">
                <form-field-ssn
                    v-model="ssn"
                    name="ssn"
                    prompt="Confirm social security number"
                    class="form-field"
                    :last-four-only="false"
                />
                <p class="small text-muted text-start mt-1">
                    To ensure your security and identity protection, we need to confirm your full social security number. All of your data is encrypted and private.
                </p>
            </div>
            <div class="d-grid">
                <form-button
                    class="mt-3"
                    :label="$t('global.cta.continue')"
                    type="submit"
                    event-name="click_button_submit_identity_questions_form"
                />
            </div>
        </form-container>

        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.creditLimit"
            />
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { postChallengeQuestionAnswers, getIDologyChallengeQuestions, postQuestionAnswersWithSSN } from '@/services/idology'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { getNextRoute } from '@/flow/flowController'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { KbaStatus, logKbaStart, updateKbaStatus } from '@/services/remoteNotarizationApi'
    import originationMixin from '@/mixins/originationMixin'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import assert from 'assert'

    const VerificationState = {
        IDENTITY_QUESTIONS: 'identityQuestions',
        CHALLENGE_QUESTIONS: 'challengeQuestion',
    }

    export default {
        components: {
            'form-container': FormContainer,
            'onboarding-layout': OnboardingLayout,
            'form-radio-group': FormRadioGroup,
            'form-field-ssn': FormFieldSsn,
            'form-button': FormButton,
            'offer-info-card': OfferInfoCard,
            'help-list-item': HelpListItem,
        },
        mixins: [originationMixin, computeCreditOfferFromStorageMixin],
        data() {
            return {
                ssn: '',
                loading: true,
                idologyData: null,
                questions: [],
                questionData: [],
                answerData: [],
                isChallengeQuestion: false,
                state: VerificationState.IDENTITY_QUESTIONS,
            }
        },
        mounted: async function () {
            try {
                await logKbaStart()
                logger.info('KBA start logged')

                logger.info(`history length: ${window.history.length}`)
                this.idologyData = JSON.parse(appSessionStorage.getItem(localStorageKey.identityQA))
                // sometimes there are no questions, but we always want to show them the SSN field
                this.questionData = this.idologyData?.questions
                this.loading = false
                this.$logEvent('view_identity_questions')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            onSubmit: async function () {
                try {
                    if (this.loading) {
                        return
                    }

                    const isValid = await this.$refs.form.$refs.observer.validate()
                    if (!isValid) {
                        const el = document.querySelector('.is-invalid:first-of-type')
                        el?.scrollIntoView()
                        return
                    }
                    this.errorText = ''
                    this.loading = true

                    if (this.state === VerificationState.IDENTITY_QUESTIONS) {
                        await this.submitIdentityQuestionAnswers()
                    } else if (this.state === VerificationState.CHALLENGE_QUESTIONS) {
                        await this.submitChallengeAnswers()
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                    this.loading = false
                }
            },
            submitIdentityQuestionAnswers: async function () {
                appSessionStorage.removeItem(localStorageKey.identityQA)

                let response
                const questionPayload = []
                for (let i = 0; i < this.questionData?.length; i++) {
                    questionPayload.push({
                        questionType: this.questionData[i].type,
                        answer: this.answerData[i],
                    })
                }

                const payload = {
                    answers: questionPayload.length > 0 ? questionPayload : undefined,
                    ssn: this.ssn,
                }
                response = await postQuestionAnswersWithSSN(payload, this.idologyData?.idologyQueryId)

                logger.info(`ExpectID Answer verification response: ${JSON.stringify(response.data)}`)

                // Incomplete application is actually a euphemism for "you failed and can't retry"
                if (response.data.error === 'INCOMPLETE_APPLICATION') {
                    this.$logEvent('event_identity_verification_failed_permanently')
                    logger.info(`verifyIdentity error: ${response.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    await updateKbaStatus(KbaStatus.permanentFail)
                    logger.info(`Successfully updated KBA status to ${KbaStatus.permanentFail}`)
                    return await this.$router.replace(sharedPagePaths.THANKS)
                } else if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    if (!response.data.payload.isPriorityApplication) {
                        // Continue with loan application. Don't worry about other active loan applications at this point
                        this.$logEvent('event_passed_identity_questions')
                        await updateKbaStatus(KbaStatus.pass)
                        logger.info(`Successfully updated KBA status to ${KbaStatus.pass}`)
                        return await this.$router.replace(getNextRoute(this.$router))
                    }
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    return (window.location.href = `${process.env.VUE_APP_ORIGINATION_BASE_URL}/shared/redirect?p=${response.data.payload.returnToken2}`)
                } else if (!response.data.success) {
                    this.$logEvent('event_identity_verification_failed', { status: 'retryable' })
                    // This is a temporary state than may be resolved by retrying KBA
                    logger.info(`verifyIdentity error: ${response.data.error} and status: ${response.data.payload?.status} -> presenting ${sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY}`)
                    await updateKbaStatus(KbaStatus.fail)
                    logger.info(`Successfully updated KBA status to ${KbaStatus.fail}`)
                    return await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }

                const status = response.data?.payload?.status || 'na'
                if (status === KbaStatus.pass) {
                    // answered all questions correctly
                    this.$logEvent('event_passed_identity_questions')
                    await updateKbaStatus(KbaStatus.pass)
                    logger.info(`Successfully updated KBA status to ${KbaStatus.pass}`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === KbaStatus.allowableFail) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.allowableFail)
                    logger.info(`Updated KBA status to ${KbaStatus.allowableFail}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === KbaStatus.verifySSN) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.verifySSN)
                    logger.info(`Updated KBA status to ${KbaStatus.verifySSN}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === 'challenge') {
                    //one wrong answer, get challenge question
                    this.$logEvent('event_getting_identity_challenge_questions')
                    logger.info(`Get Challenge questions`)
                    this.isChallengeQuestion = true
                    await this.getIDologyChallengeQuestions()
                    this.loading = false
                } else {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.fail)
                    logger.info(`Successfully updated KBA status to ${KbaStatus.fail}`)
                    logger.info(`Catching unanticipated response data payload after submitting identity question answers with a status of: ${status}`)
                    await this.$router.replace(sharedPagePaths.THANKS)
                }
            },
            getIDologyChallengeQuestions: async function () {
                this.state = VerificationState.CHALLENGE_QUESTIONS
                const response = await getIDologyChallengeQuestions()
                logger.info(`Challenge Questions: ${JSON.stringify(response.data)}`)

                const status = response.data?.status || 'na'
                if (status === KbaStatus.pass) {
                    logger.info(`Got challenge questions, showing to user`)
                    this.questionData = response.data.questions
                } else if (status === KbaStatus.allowableFail) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.allowableFail)
                    logger.info(`Updated KBA status to ${KbaStatus.allowableFail}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === KbaStatus.verifySSN) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.verifySSN)
                    logger.info(`Updated KBA status to ${KbaStatus.verifySSN}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else {
                    this.$logEvent('trigger_manual_identity_review')
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.fail)
                    logger.info(`Successfully updated KBA status to ${KbaStatus.fail}`)
                    logger.info(`Catching unanticipated response data payload after submitting identity question answers with a status of: ${status}`)
                    await this.$router.replace(sharedPagePaths.THANKS)
                }
            },
            submitChallengeAnswers: async function () {
                const payload = []
                payload.push({
                    questionType: this.questionData[0].type,
                    answer: this.answerData[0],
                })

                const response = await postChallengeQuestionAnswers(payload, this.idologyData?.idologyQueryId)
                logger.info(`Challenge Answer verification response: ${JSON.stringify(response.data)}`)
                if (response.data.error === 'INCOMPLETE_APPLICATION') {
                    this.$logEvent('event_identity_verification_failed_permanently')
                    logger.info(`verifyIdentity error: ${response.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    await updateKbaStatus(KbaStatus.permanentFail)
                    logger.info(`Successfully updated KBA status ${KbaStatus.permanentFail}`)
                    return await this.$router.replace(sharedPagePaths.THANKS)
                } else if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    if (!response.data.payload.isPriorityApplication) {
                        // Continue with loan application. Don't worry about other active loan applications at this point
                        this.$logEvent('event_passed_identity_questions')
                        await updateKbaStatus(KbaStatus.pass)
                        logger.info(`Successfully updated KBA status ${KbaStatus.pass}`)
                        return await this.$router.replace(getNextRoute(this.$router))
                    }
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    return (window.location.href = `${process.env.VUE_APP_ORIGINATION_BASE_URL}/shared/redirect?p=${response.data.payload.returnToken2}`)
                }

                const status = response.data?.payload?.status
                if (response.data.success) {
                    this.$logEvent('event_passed_identity_questions')
                    await updateKbaStatus(KbaStatus.pass)
                    logger.info(`Successfully updated KBA status ${KbaStatus.pass}`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === KbaStatus.allowableFail) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.allowableFail)
                    logger.info(`Updated KBA status to ${KbaStatus.allowableFail}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === KbaStatus.verifySSN) {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.verifySSN)
                    logger.info(`Updated KBA status to ${KbaStatus.verifySSN}, pushing to notarization anyways`)
                    await this.$router.replace(getNextRoute(this.$router))
                } else {
                    this.$logEvent('event_identity_verification_failed', { status })
                    await updateKbaStatus(KbaStatus.fail)
                    logger.info(`Successfully updated KBA status ${KbaStatus.fail}`)
                    logger.info(`Catching unanticipated response data payload after submitting challenge answers with a status of: ${status}`)
                    await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }
            },
        },
    }
</script>
