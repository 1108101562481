// /notary/... is for KBA flow in the notary
import { httpClient } from '@/utils/http-client'

const verifyApplicant = async () => {
    const path = '/notary/verifyApplicant'
    return await httpClient.get(path)
}

// Idology endpoint postQuestionAnswers - IDology presents applicant with 4 screening questions and SSN field
// This endpoint is used to post the answers for verification
const postQuestionAnswersWithSSN = async (payload, idologyQueryId) => {
    const path = '/notary/idology_qa_answers_with_ssn'
    return await httpClient.post(path, {
        idologyQueryId,
        ...payload,
    })
}

// Idology endpoint getIDologyChallengeQuestions - if the applicant answers 1 question incorrectly, the
// applicant is presented with a challenge question. if the applicant answers more than 1 question incorrectly,
// IDology fails the applicant instantly
const getIDologyChallengeQuestions = async () => {
    const path = '/notary/challenge_questions'
    return await httpClient.get(path)
}

// Idology endpoint postChallengeQuestionAnswers - this endpoint is used to submit answer for challenge question
// for verification. if the applicant answers this correctly we route them to the prequal offer page.
const postChallengeQuestionAnswers = async (payload, idologyQueryId) => {
    const path = '/notary/idology_challenge_answers'
    return await httpClient.post(path, {
        idologyQueryId,
        answers: payload,
    })
}

export { verifyApplicant, getIDologyChallengeQuestions, postChallengeQuestionAnswers, postQuestionAnswersWithSSN }
